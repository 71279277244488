<template>
  <div class="container-fluid g-0" style="padding: 0px 8rem">
    <div v-if="isTemplateDirty" class="floating-save position-absolute rounded d-flex gap-7 p-2">
      <div class="text-white d-flex align-items-center">
        <EditIcon />
        <p class="fw-bold mx-2">{{ $t('automation.templateManagement.saveChanges.changes') }}</p>
        <p class="opacity">{{ $t('automation.templateManagement.saveChanges.context') }}</p>
      </div>
      <div>
        <Button style="background-color: #34373d" @click="cancelChanges">{{ $t('commons.cancel') }}</Button>
        <Button @click="handleSaveChanges">{{ $t('commons.save') }}</Button>
      </div>
    </div>

    <div class="my-7">
      <h1>{{ $t('routes.automationTemplateManagement') }}</h1>
      <p v-if="!loading" class="fw-bold">{{ supplier.name }}</p>
    </div>

    <template v-if="!loading">
      <div class="h-100">
        <MenuBar
          :templates="templates"
          class="mb-4"
          :mode.sync="mode"
          :template-id.sync="selectedTemplateId"
          :template="selectedTemplate"
          :is-original="isOriginalTemplate"
          :no-original="noOriginalTemplate"
          :is-editable="isEditable"
          :is-dirty="isTemplateDirty"
          @edit="nameModalOpen = true"
          @create="openNameModal"
          @delete="deleteActionType = 'deleteTemplate'"
          @archive="archiveTemplateModalOpen = true"
          @un-archive="handleUnArchiveTemplate"
          @run-automation="handleAutomation"
          @structure-params="structureParamsModalOpen = true"
          @save="saveChanges"
        />
        <div
          v-if="automationResult && mode === STATE_MODE.AUTOMATION"
          class="alert text-center"
          :class="automationResult.metadata.populate ? 'alert-success' : 'alert-danger'"
        >
          <template v-if="automationResult.metadata.populate">
            {{ $t('automation.templateManagement.automationResult.pass') }}
          </template>
          <template v-else>{{ $t('automation.templateManagement.automationResult.fail') }}</template>
        </div>
        <div class="row flex-grow-1">
          <div class="col-6">
            <TemplateViewer
              :editable="isEditable"
              :document="document"
              :template="selectedTemplate"
              @fields-update="handleFieldsUpdate"
              @field-click="handleFieldClick"
            />
          </div>
          <div class="col-6">
            <div
              class="card p-4"
              style="height: 75vh; position: sticky; top: 20px; max-height: calc(75vh - 40px); overflow-y: auto"
            >
              <template v-if="mode === STATE_MODE.TEMPLATE_EDITING">
                <TemplateForm
                  v-if="isEditable && formField"
                  :template="selectedTemplate"
                  :form="formField"
                  @submit="handleTemplateFormUpdate"
                  @delete="deleteActionType = 'deleteField'"
                  @cancel="formField = null"
                />

                <div v-else class="h-100 d-flex justify-content-center align-items-center">
                  <div
                    v-if="!selectedTemplateId && noOriginalTemplate"
                    class="h-100 lh-1 gap-1 d-flex align-items-center justify-content-center"
                  >
                    <p>{{ $t('automation.templateManagement.noTemplate') }},</p>
                    <Button @click="openNameModal">
                      {{ $t('automation.templateManagement.newTemplate') }}
                    </Button>
                  </div>
                  <p v-else-if="isEditable">
                    {{ $t('automation.templateManagement.templateForm.defineField') }}
                  </p>
                  <p v-else-if="selectedTemplate && selectedTemplate.documentId && !isOriginalTemplate">
                    <i18n path="automation.templateManagement.templateForm.routeToOriginalDocument">
                      <template #click>
                        <Button type="link" @click="routeToOtherOriginalDocument">
                          {{ $t('automation.templateManagement.templateForm.clickHere') }}
                        </Button>
                      </template>
                    </i18n>
                  </p>
                  <p v-else>
                    {{
                      $t('automation.templateManagement.templateForm.documentWithOtherTemplate', {
                        name: originalTemplateName,
                      })
                    }}
                  </p>
                </div>
              </template>

              <template v-if="mode === STATE_MODE.AUTOMATION">
                <DigitalDocument v-if="digitalDocument" :document="digitalDocument" />
                <div v-else class="d-flex align-items-center justify-content-center h-100">
                  <p>
                    <span>{{ $t('automation.templateManagement.automationAction.needTo') }}</span>
                    <Button class="mx-1 p-0" type="text" @click="handleAutomation">
                      {{ $t('automation.templateManagement.automationAction.runAutomation') }}
                    </Button>
                    <span>{{ $t('automation.templateManagement.automationAction.toSeeResults') }}</span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <NameModal
        v-if="nameModalOpen"
        :template-name="currentTemplateName"
        :is-manual="currentTemplateIsManual"
        :locale="currentTemplateLocale"
        :supplier="supplier"
        :document-type="documentType"
        @submit="handleNameSubmit"
        @close="nameModalOpen = false"
      />
      <ArchivedModal
        v-if="archiveTemplateModalOpen"
        :template-name="currentTemplateName"
        @approve="handleArchiveTemplate"
        @close="archiveTemplateModalOpen = false"
      />
      <ReplaceTemplateModal
        v-if="replaceTemplateModalOpen"
        :template-name="currentTemplateName"
        @submit="handleTemplateReplace"
        @close="replaceTemplateModalOpen = false"
      />
      <UnArchivedConflictModal
        v-if="unArchiveConflictTemplateModalOpen"
        :in-use-conflict-template-name="conflictTemplateName"
        @close="handleCloseConflictModal"
      />
      <StructureParamsDrawer
        v-if="structureParamsModalOpen"
        :template="selectedTemplate"
        :edit="isEditable"
        @submit="handleStructureParamsSubmit"
        @close="structureParamsModalOpen = false"
      />
      <CannotUpdateArchivedTemplateModal
        v-if="cannotUpdateArchivedTemplateModalOpen"
        @close="handleDiscardArchiveTemplateChanges"
      />
      <el-dialog
        v-if="deleteActionType"
        visible
        width="30%"
        custom-class="rounded"
        :before-close="() => (deleteActionType = null)"
      >
        <template #title>
          <h2>
            {{ $t('automation.templateManagement.deleteModal.title') }}
          </h2>
        </template>
        <p class="text-typography-primary">
          {{ $t(`automation.templateManagement.deleteModal.${deleteActionType}`, { name: currentTemplateName }) }}
        </p>
        <span slot="footer" class="dialog-footer">
          <Button type="secondary" @click="deleteActionType = null">
            {{ $t('commons.cancel') }}
          </Button>
          <Button type="primary" @click="deleteAction">
            {{ $t('commons.apply') }}
          </Button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { omit, equals, isNil, isEmpty } from 'ramda';
import { computed, getCurrentInstance } from 'vue';

import { useSupplier } from '@/modules/suppliers';
import { useDocumentNew2 } from '@/modules/document/compositions';
import { DigitalDocument } from '@/modules/documentModal';
import { Button } from '@/modules/core';
import { EditIcon } from '@/assets/icons';

import {
  useTemplates,
  documentAutomation,
  useTemplateCreate,
  useTemplateUpdate,
  useTemplateDelete,
} from './compositions';

import { STATE_MODE } from './constants';
import {
  TemplateForm,
  MenuBar,
  NameModal,
  TemplateViewer,
  ArchivedModal,
  ReplaceTemplateModal,
  UnArchivedConflictModal,
  CannotUpdateArchivedTemplateModal,
  StructureParamsDrawer,
} from './components/template/components';
import { getFormFromField, updateFieldFromForm } from './components/template/tools/formField';

const omitTypenameAndNull = (key, value) => (key === '__typename' || value === null ? undefined : value);
const cleanNullsAndTypename = (data) => (data ? JSON.parse(JSON.stringify(data), omitTypenameAndNull) : null);

export default {
  components: {
    MenuBar,
    TemplateForm,
    DigitalDocument,
    NameModal,
    ArchivedModal,
    UnArchivedConflictModal,
    ReplaceTemplateModal,
    CannotUpdateArchivedTemplateModal,
    Button,
    TemplateViewer,
    StructureParamsDrawer,
    EditIcon,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const supplierId = computed(() => root.$route.query.supplierId);
    const documentId = computed(() => root.$route.query.documentId);
    const documentType = computed(() => root.$route.query.documentType);
    const templateId = computed(() => root.$route.query.templateId);
    const variables = computed(() => ({
      supplierId: supplierId.value,
      documentType: documentType.value,
      signedUrl: false,
    }));

    const { templates, loading: templatesLoading, refetch: refetchTemplates } = useTemplates(variables);
    const { templateCreate } = useTemplateCreate();
    const { templateUpdate } = useTemplateUpdate();
    const { templateDelete } = useTemplateDelete();
    const { supplier, loading: supplierLoading } = useSupplier(supplierId);
    const { document, loading: documentLoading } = useDocumentNew2(documentId);

    return {
      templateId,
      documentId,
      supplierId,
      templates,
      refetchTemplates,
      supplier,
      document,
      documentType,
      templateCreate,
      templateUpdate,
      templateDelete,
      loading: computed(() => templatesLoading.value || supplierLoading.value || documentLoading.value),
    };
  },
  data() {
    return {
      STATE_MODE,
      nameModalOpen: false,
      archiveTemplateModalOpen: false,
      unArchiveConflictTemplateModalOpen: false,
      replaceTemplateModalOpen: false,
      cannotUpdateArchivedTemplateModalOpen: false,
      structureParamsModalOpen: false,
      selectedTemplateId: null,
      automationResult: null,
      formField: null,
      deleteActionType: null,
      mode: STATE_MODE.TEMPLATE_EDITING,
      selectedTemplate: null,
      conflictTemplateName: null,
    };
  },
  computed: {
    currentTemplateName() {
      return this.selectedTemplate?.name;
    },
    currentTemplateDocumentId() {
      return this.selectedTemplate?.documentId;
    },
    currentTemplateIsManual() {
      if (isNil(this.selectedTemplate?.isManual)) return null;
      return this.selectedTemplate?.isManual === true ? 'manual' : 'digital';
    },
    currentTemplateLocale() {
      return this.selectedTemplate?.locale;
    },
    originalTemplateName() {
      return this.templates.find((t) => t.documentId === this.documentId)?.name;
    },
    isCurrentTemplateArchived() {
      return this.selectedTemplate?.isArchived;
    },

    isOriginalTemplate() {
      if (!this.selectedTemplate) return false;
      if (this.documentId === this.selectedTemplate.documentId && this.templateId === this.selectedTemplate.id)
        return true;

      return this.selectedTemplateId === this.templates.find((t) => t.documentId === this.documentId)?.id;
    },
    digitalDocument() {
      return this.automationResult?.document
        ? { ...this.automationResult?.document, structureParams: this.document.structureParams }
        : null;
    },
    noOriginalTemplate() {
      // Return true if no template is selected and no template matches the documentId
      if (!this.selectedTemplate) {
        return this.templates?.every((t) => this.documentId !== t.documentId);
      }

      // Check if a non-archived template with the same documentId exists
      const hasNonArchivedTemplate = this.templates.some(
        (t) => t.documentId === this.selectedTemplate.documentId && !t.isArchived
      );

      return !hasNonArchivedTemplate;
    },
    isEditable() {
      return (
        this.mode === STATE_MODE.TEMPLATE_EDITING &&
        this.selectedTemplateId &&
        (this.isOriginalTemplate || (!this.selectedTemplate?.documentId && this.noOriginalTemplate))
      );
    },
    isTemplateDirty() {
      const originalTemplate = this.templates.find((template) => template.id === this.selectedTemplateId);
      const draft = cleanNullsAndTypename(this.selectedTemplate);
      const original = cleanNullsAndTypename(originalTemplate);
      return !equals(draft, original);
    },

    canSaveTemplateChanges() {
      const originalTemplate = this.templates.find((template) => template.id === this.selectedTemplateId);
      const draft = cleanNullsAndTypename(this.selectedTemplate);
      const original = cleanNullsAndTypename(originalTemplate);
      return equals(draft.structureParams, original.structureParams);
    },
    thereAreChangesThatNeedToReplaceTemplate() {
      return this.isTemplateDirty && !this.canSaveTemplateChanges && !this.isCurrentTemplateArchived;
    },
    changesCannotBeUpdated() {
      return this.isTemplateDirty && !this.canSaveTemplateChanges && this.isCurrentTemplateArchived;
    },
  },
  watch: {
    mode() {
      this.automationResult = null;
    },
    templates() {
      this.updateSelectedTemplateBasedOnToken();
    },
    document() {
      this.updateSelectedTemplateBasedOnToken();
    },
    selectedTemplateId(newSelectedId) {
      this.formField = null;
      if (newSelectedId)
        this.selectedTemplate = cleanNullsAndTypename(this.templates.find((template) => template.id === newSelectedId));
      else this.selectedTemplate = null;
    },
  },
  methods: {
    updateSelectedTemplateBasedOnToken() {
      const sameTokenTemplateId = this.templates?.find((t) => t.structureToken === this.document?.structureToken)?.id;
      if (sameTokenTemplateId) {
        this.selectedTemplateId = sameTokenTemplateId;
      } else {
        this.selectedTemplateId = null;
      }
    },
    async handleNameSubmit({ name, isManual, locale }) {
      const loading = this.$loading();
      this.nameModalOpen = false;
      try {
        if (!this.selectedTemplateId) {
          const response = await this.templateCreate({
            data: {
              documentId: this.documentId,
              supplierId: this.supplierId,
              documentType: this.documentType,
              name,
              isManual,
              locale,
              fields: [],
              structureParams: cleanNullsAndTypename(this.document.structureParams),
            },
          });
          await this.refetchTemplates();
          this.selectedTemplateId = response.data.templateCreate.id;
        } else {
          await this.templateUpdate({
            id: this.selectedTemplateId,
            data: {
              name,
            },
          });
          await this.refetchTemplates();
        }
        this.selectedTemplate = cleanNullsAndTypename(
          this.templates.find((template) => template.id === this.selectedTemplateId)
        );
      } finally {
        loading.close();
      }
    },
    async handleTemplateDelete() {
      const loading = this.$loading();
      try {
        await this.templateDelete({
          id: this.selectedTemplateId,
        });
        await this.refetchTemplates();
        this.selectedTemplateId = null;
      } finally {
        loading.close();
      }
    },
    async handleAutomation() {
      const loading = this.$loading();
      try {
        const result = await documentAutomation(this.documentId);
        if (result.metadata.error) {
          this.$notify.error({ title: this.$t('errors.oopsTitle'), message: result.metadata.error });
        }
        this.automationResult = result;
      } catch (error) {
        this.$notify.error({ title: this.$t('errors.oopsTitle'), message: this.$t('errors.action') });
      } finally {
        loading.close();
      }
    },
    handleFieldClick(_, index) {
      this.openTemplateForm(this.selectedTemplate.fields[index], index);
    },
    handleFieldsUpdate(fields) {
      const updatedFields = fields.map((field, index) => {
        return {
          ...this.selectedTemplate.fields[index],
          ...omit(['fields'], field),
          fields: this.selectedTemplate.fields[index]?.fields?.map((subField, subIndex) => {
            return {
              ...subField,
              ...(field.fields?.[subIndex] ?? field), // for one column tables
            };
          }),
        };
      });
      this.selectedTemplate.fields = updatedFields;
      if (fields.length > this.selectedTemplate.fields.length) {
        const fieldIndex = fields.length - 1;
        this.openTemplateForm(fields[fieldIndex], fieldIndex);
      }
    },
    handleTemplateFormUpdate(formFieldData) {
      const newFields = [...this.selectedTemplate.fields];
      newFields[formFieldData.fieldIndex] = updateFieldFromForm(newFields[formFieldData.fieldIndex], formFieldData);
      this.selectedTemplate.fields = newFields;
      this.formField = null;
    },
    handleTemplateFieldDelete() {
      const updatedFields = [...this.selectedTemplate.fields];
      updatedFields.splice(this.formField.fieldIndex, 1);
      this.selectedTemplate.fields = updatedFields;
      this.formField = null;
    },
    openTemplateForm(field, index) {
      this.formField = { ...getFormFromField(field), fieldIndex: index };
    },
    openNameModal() {
      this.nameModalOpen = true;
      this.selectedTemplateId = null;
    },
    deleteAction() {
      switch (this.deleteActionType) {
        case 'deleteTemplate':
          this.handleTemplateDelete();
          break;
        case 'deleteField':
          this.handleTemplateFieldDelete();
          break;
        default:
          break;
      }
      this.deleteActionType = null;
    },
    handleStructureParamsSubmit(newStructureParams) {
      this.selectedTemplate.structureParams = newStructureParams;
      this.structureParamsModalOpen = false;
    },
    async saveChanges() {
      const data = omit(['id', 'structureToken'], this.selectedTemplate);

      const missingFields = this.getMissingDrawFields(data.structureParams, data.fields);
      if (!isEmpty(missingFields)) {
        await this.$confirm(
          `${this.translateMissingFields(missingFields)} ${this.$tc(
            'automation.templateManagement.missingFieldsModal.body',
            missingFields.length === 1
          )} ${this.$t('automation.templateManagement.missingFieldsModal.proceed')}`,
          { type: 'warning' }
        )
          .then(() => this.updateTemplateHandler(data))
          .catch(() => false);
      } else {
        this.updateTemplateHandler(data);
      }
    },
    async updateTemplateHandler(data) {
      const loading = this.$loading();
      try {
        await this.templateUpdate({
          id: this.selectedTemplate.id,
          data: {
            name: data.name,
            documentId: this.documentId,
            fields: data.fields,
            isArchived: data.isArchived,
          },
        });
        await this.refetchTemplates();
        loading.close();
        this.$message.success(this.$t('commons.messages.action.success'));
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors[0]?.extensions?.response.status === 400)
          this.handleValidationError(error.graphQLErrors[0].extensions.response.body);
      } finally {
        loading.close();
      }
    },
    translateMissingFields(structureParamsFields) {
      return structureParamsFields.map((fields) => {
        const [rootField, fieldName] = fields;
        switch (rootField) {
          case 'items':
            return `${this.$t(`document.exports.schema.itemsFields.${fieldName}`)} (${this.$t(
              `document.exports.schema.fields.items`
            )})`;
          case 'references':
            return `${this.$t(`document.exports.schema.referencesFields.${fieldName}`)} (${this.$t(
              `document.exports.schema.fields.references`
            )})`;
          case 'generalCharges':
            return `${this.$t(`document.exports.schema.generalChargesFields.${fieldName}`)} (${this.$t(
              `document.exports.schema.fields.generalCharges`
            )})`;
          default:
            return this.$t(`document.exports.schema.fields.${rootField}`);
        }
      });
    },
    // get fields that defined in SP but not draw
    getMissingDrawFields(structureParams, fields, parentStructureParams = []) {
      const allNestedStructureParams = [];
      const structureParamsNotMatchedToFields = Object.entries(structureParams)
        .map(([structureParam, structureParamValue]) => {
          if (!structureParamValue) return [];
          const relevantField = (fields ?? []).find((field) => field.key === structureParam);
          if (structureParam === 'items' || structureParam === 'references' || structureParam === 'generalCharges') {
            const nestedStructureParams = this.getMissingDrawFields(structureParamValue, relevantField?.fields, [
              ...parentStructureParams,
              structureParam,
            ]);
            allNestedStructureParams.push(...nestedStructureParams);
            if (nestedStructureParams.length) return [];
            if (!nestedStructureParams.length && !structureParamValue)
              return [...parentStructureParams, structureParam];
          } else if (!relevantField) {
            return [...parentStructureParams, structureParam];
          }

          return [];
        })
        .filter((structureParamArr) => structureParamArr.length);
      return [...structureParamsNotMatchedToFields, ...allNestedStructureParams];
    },
    routeToOtherOriginalDocument() {
      this.$router.replace({
        query: {
          documentId: this.selectedTemplate.documentId,
          supplierId: this.selectedTemplate.supplierId,
          documentType: this.selectedTemplate.documentType,
          templateId: this.selectedTemplate.id,
        },
      });
    },
    handleValidationError({ details }) {
      const errorsToShow = details
        .filter(
          (validationDetail) => validationDetail.path[0] === 'fields' || validationDetail.path[0] === 'structureParams'
        )
        .sort((vdA, vdB) => vdA.path[0].localeCompare(vdB.path[0]))
        .map(({ context }) => {
          const { field, structureParam } = context;
          if (field) {
            const fieldName = this.generateValidationErrorString(field);
            const text = this.$t('automation.templateManagement.templateForm.validationError.field', { fieldName });
            return text;
          }
          if (structureParam) {
            const parameterName = this.generateValidationErrorString(structureParam);
            const text = this.$t('automation.templateManagement.templateForm.validationError.structureParam', {
              parameterName,
            });
            return text;
          }
          const unhandledProperty = Object.keys(context)[0];
          const unhandledError = context[unhandledProperty].join('.');
          return unhandledError;
        });

      this.buildAlertMessage(errorsToShow);
    },
    generateValidationErrorString(propertiesPath) {
      const getKeyLabel = (fieldType) => this.$t(`document.exports.schema.fields.${fieldType}`);
      const getTableKeyLabel = (fieldType, key) =>
        this.$t(
          key === 'index'
            ? 'automation.templateManagement.templateForm.form.fieldIndex'
            : `document.exports.schema.${fieldType}Fields.${key}`
        );
      if (propertiesPath.length > 1) {
        const parentLabel = getKeyLabel(propertiesPath[0]);
        const childLabel = getTableKeyLabel(propertiesPath[0], propertiesPath[1]);
        const text = this.$t('automation.templateManagement.templateForm.validationError.nested', {
          childLabel,
          parentLabel,
        });
        return `"${text}"`;
      }
      return `"${getKeyLabel(propertiesPath[0])}"`;
    },
    buildAlertMessage(errorTexts) {
      const getText = (key) => this.$t(`automation.templateManagement.templateForm.validationError.${key}`);
      const elm = this.$createElement;
      const listItemStyle = {
        display: 'list-item',
        listStyleType: 'disc',
        listStylePosition: 'inside',
      };
      const title = getText('title');
      const subtitle = getText('subtitle');
      const message = elm('div', { class: 'text-typography-primary' }, [
        elm('p', { class: 'fw-bold' }, subtitle),
        ...errorTexts.map((text) => elm('p', { style: listItemStyle }, text)),
      ]);
      this.$msgbox(message, title, { type: 'error' });
    },
    cancelChanges() {
      this.selectedTemplate = cleanNullsAndTypename(
        this.templates.find((template) => template.id === this.selectedTemplateId)
      );
      this.formField = null;
    },
    async handleUnArchiveTemplate() {
      const foundTemplateWithSameDocumentIdAndUnArchived = this.templates.find((template) => {
        if (
          template.id !== this.selectedTemplateId &&
          template.documentId === this.currentTemplateDocumentId &&
          !template.isArchived
        )
          return template;
      });

      if (foundTemplateWithSameDocumentIdAndUnArchived)
        return this.handleUnArchiveConflict(foundTemplateWithSameDocumentIdAndUnArchived.name);

      const loading = this.$loading();
      try {
        await this.templateUpdate({
          id: this.selectedTemplateId,
          data: {
            isArchived: false,
          },
        });

        await this.refetchTemplates();
        this.selectedTemplate = cleanNullsAndTypename(
          this.templates.find((template) => template.id === this.selectedTemplateId)
        );
      } finally {
        loading.close();
      }
    },
    handleUnArchiveConflict(conflictedUnArchiveTemplateName) {
      this.conflictTemplateName = conflictedUnArchiveTemplateName;
      this.unArchiveConflictTemplateModalOpen = true;
    },
    handleCloseConflictModal() {
      this.unArchiveConflictTemplateModalOpen = false;
      this.conflictTemplateName = null;
    },
    handleReplaceModal() {
      this.replaceTemplateModalOpen = true;
    },
    async handleTemplateReplace(payload) {
      this.replaceTemplateModalOpen = false;
      const loading = this.$loading();

      try {
        await this.templateUpdate({
          id: this.selectedTemplateId,
          data: {
            isArchived: true,
          },
        });

        try {
          const response = await this.templateCreate({
            data: {
              documentId: this.selectedTemplate.documentId,
              supplierId: this.selectedTemplate.supplierId,
              documentType: this.selectedTemplate.documentType,
              name: payload.name,
              isManual: this.selectedTemplate.isManual,
              locale: this.selectedTemplate.locale,
              fields: this.selectedTemplate.fields,
              structureParams: cleanNullsAndTypename(this.selectedTemplate.structureParams),
            },
          });

          await this.refetchTemplates();
          this.selectedTemplateId = response.data.templateCreate.id;
        } catch (error) {
          // Show create validation error modal
          if (error.graphQLErrors && error.graphQLErrors[0]?.extensions?.response.status === 400)
            this.handleValidationError(error.graphQLErrors[0].extensions.response.body);
          // Roll back the archive template we did since we could not create the new template
          await this.templateUpdate({
            id: this.selectedTemplateId,
            data: {
              isArchived: false,
            },
          });
          await this.refetchTemplates();
        }
      } finally {
        loading.close();
      }
    },
    async handleArchiveTemplate() {
      this.archiveTemplateModalOpen = false;
      const loading = this.$loading();
      try {
        await this.templateUpdate({
          id: this.selectedTemplateId,
          data: {
            isArchived: true,
          },
        });

        await this.refetchTemplates();

        this.selectedTemplate = cleanNullsAndTypename(
          this.templates.find((template) => template.id === this.selectedTemplateId)
        );
      } finally {
        loading.close();
      }
    },
    handleDiscardArchiveTemplateChanges() {
      this.cannotUpdateArchivedTemplateModalOpen = false;
    },
    handleSaveChanges() {
      if (this.isTemplateDirty && this.canSaveTemplateChanges) return this.saveChanges();
      if (this.thereAreChangesThatNeedToReplaceTemplate) return (this.replaceTemplateModalOpen = true);
      if (this.changesCannotBeUpdated) return (this.cannotUpdateArchivedTemplateModalOpen = true);
    },
  },
};
</script>
<style lang="scss" scoped>
.floating-save {
  z-index: 1;
  bottom: 32px;
  right: 43vw;
  background-color: #222222;

  .opacity {
    opacity: 0.8;
  }
}
</style>
